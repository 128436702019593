import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Alex',
  subtitle: "I'm a Front-End Web Developer",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'alex2.jpg',
  paragraphOne:
    'Clumsily building my first HTML/CSS static website out of sheer curiousity sparked an interest in web development that resulted in me teaching myself JavaScript as well as undertaking a degree in Software Engineering. ',
  paragraphTwo:
    'During my studies I picked up knowledge of Object Oriented Programming, APIs, Design Patterns and Agile and have since utilised this in the websites I have built.',
  paragraphThree:
    "Today I'm capable of working with modern component based frameworks such as VueJS to create responsive, dynamic, cross platform websites capable of querying APIs and modern features such as offline browsing.",
  paragraphFour:
    'To continue my journey as a developer I am seeking a professional role as a Front End Web Developer so I can be in an environment where I can improve my skills and expose myself to the exciting new technologies that emerge in an industry I am passionate about.',
  // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'cocktaildb.png',
    title: 'A Cocktail Database PWA',
    info: 'JavaScript - VueJS - WorkBox - Google Firebase - Bootstrap',
    info2:
      'This client-side website dynamically queries a cocktail database API to display information on 600+ cocktails. Users can register an account, take a profile picture and favourite cocktails.',
    url: 'https://cocktaildb.app/',
    repo: 'https://github.com/alex-wjb/cocktail-db', // if no repo, the button will not show up,
    listItems: [
      'Progressive Web App: mobile responsive, installable, device camera access',
      'Offline browsing after first visit using Service Worker API caching strategies',
      'Dynamic search and results display using VueJs Computed Properties, Lifecycle Hooks and Watchers',
      'Single Page Application routing using Vue Router',
      'User authentication with security rules and hosting via Google Firebase',
    ],
  },
  {
    id: nanoid(),
    img: 'covid-dash.png',
    title: 'A Covid-19 Information Dashboard',
    info: 'JavaScript - HTML/CSS - ChartJS - GitHub Pages',
    info2:
      'An interactive dashboard that utilises a UK Government API and the ChartJS library to graphically display live Covid-19 statistics.',
    url: 'https://alex-wjb.github.io/covid-dashboard/',
    repo: 'https://github.com/alex-wjb/covid-dashboard', // if no repo, the button will not show up
    listItems: [
      'Data filterable by nation',
      'JS Fetch API used to asynchronously retrieve real-time JSON data',
      'Fully responsive charts with a custom cursor to display data point info',
      'Custom CSS Styling',
    ],
  },
  {
    id: nanoid(),
    img: 'e-health.png',
    title: 'A Medical Practice Patient Registration Site',
    info: 'JavaScript - HTML/CSS - PHP - CodeIgniter - SQL - Bootstrap',
    info2:
      'Users can create an account, fill out their patient application and log in to check the status or update their details.',
    url: 'https://e-health-site.000webhostapp.com/',
    repo: 'https://github.com/alex-wjb/e-health-dev', // if no repo, the button will not show up
    listItems: [
      'Adopts an MVC architecture using the CodeIgniter PHP framework',
      'Browser cookies and user sessions implemented with PHP',
      'Seperate admin view to review applications',
      'Custom client and server side form validation',
      'Dynamic form validation error messages using JS',
      'Password hashing and XXS attack prevention',
      'Full CRUD operations on an SQL database',
    ],
  },
  {
    id: nanoid(),
    img: 'cardiomyopathy.png',
    title: 'A Cardiomyopathy Experimental Data Compilation Site',
    info: 'JavaScript - VueJS - Google Firebase - Bootstrap',
    info2:
      'Built within a small team following Agile. Users can upload experimental data, query and compare data on different genetic mutations and view results graphically. ',
    url: 'https://cardiomyopathy-mmu.web.app/',
    repo: 'https://github.com/alex-wjb/Cardiomyopathy', // if no repo, the button will not show up
    listItems: [
      'Data uploadable in multiple formats including JSON and XML',
      'SPA routing using Vue Router',
      'User authentication and hosting via Google Firebase',
      'Route Guards for restricted pages',
      'Google Firestore NoSQL database to query and store data',
    ],
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Interested in working with me?',
  btn: '',
  email: 'alexanderwjb@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/alex-wjb',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
